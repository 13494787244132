@import "./../../assets/scss/variables";
.CardPreview {
  position: relative;
  width: 414px;
  .CardPreview-inner {
    width: 100%;
    height: 239px;
    background: linear-gradient(180deg, #5dbaf3 0%, #3f8fc6 100%);
    box-shadow: 0px 15px 26px rgba(55, 136, 185, 0.34);
    border-radius: $s16;
    box-sizing: border-box;
    padding: 0 $s24 $s48;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .Logo {
      text-align: left;
      width: 100px;
      height: 50px;
      //background:red;
      //margin-bottom: -6px;
    }
    .amount {
      font-weight: bold;
      font-size: 56px;
      line-height: $s48;
      color: #000000;
      mix-blend-mode: multiply;
      opacity: 0.7;
      text-align: right;
      padding-top: $s4;
      font-family: $font-family-alt;
      font-weight: 700;
      sub {
        font-size: $s32;
        vertical-align: baseline;
        display: inline-block;
        bottom: 0;
      }
      sup {
        font-size: $s24;
        vertical-align: top;
        display: inline-block;
        margin: -4px 0 0 $s8;
        top: 10px;
        font-family: "Public Sans", sans-serif;
        font-weight: 500;
      }
      //padding-top:$s24;
    }
    .CardCode {
      font-size: $s24;
      line-height: $s24;
      /* identical to box height, or 100% */
      letter-spacing: 1px;
      color: #ffffff;
      text-transform: uppercase;
      padding-top: $s32;
      font-family: $font-family-alt;
      font-weight: 600;
      > div {
        //Block de 4 lettres/chiffres pour le code de cartes

        display: inline-block;
        margin-right: $s8;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $color-primary;
    opacity: 0.4;
    border-radius: $s16;
    transform: rotate(-5.78deg);
  }
}
