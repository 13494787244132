@import "./../../assets/scss/variables";
.Input {
  label {
    font-size: $s16;
    font-weight: 600;
    font-family: $font-family-alt;
  }
  textarea:focus,
  input:focus {
    outline: none;
  }
  input,
  select {
    height: $s48;
    display: block;
    width: 100%;
    height: $s64;
    border-radius: 4px;
    border: 0;
    padding: 0 $s24;
    font-family: $font-family-alt;
    font-weight: 600;
    font-size: 18px;
    &[elementtype="mask"] {
      letter-spacing: 4px;
    }
    &[disabled]{
      background:#ddd;
    }
  }
  select {
    border: 1px solid rgba(26, 32, 44, 0.3);
    border-radius: 5px;
    height: $s48;
    padding: 0 12px;
  }
  .has-prepend {
    input,
    select {
      padding-left: 36px;
    }
  }
  .input-group {
    position: relative;
    .input-group-append {
      position: absolute;
      top: 50%;
      right: $s24;
      transform: translateY(-50%);
    }
    .input-group-prepend {
      position: absolute;
      top: 50%;
      left: 14px;
      transform: translateY(-50%);
    }
  }
  &.is-label-white {
    label {
      color: #fff;
    }
  }
  input[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }
}
