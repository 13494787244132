@import "./../../assets/scss/variables";

.CardTransaction {
  width: 100%;
  font-family: $font-family-alt;
  background: #fff;
  padding: $s24 $s24;
  border-radius: 16px;
  .details {
    display: flex;
    justify-content: space-between;
    color: #000000;
    font-family: $font-family-alt;
    font-style: normal;
    font-weight: normal;
    font-size: $s16;
    line-height: $s24;
  }
  p {
    margin-bottom: 0;
  }
  .date {
    //border-bottom: 1px solid #ebebeb;
    font-family: $font-family-alt;
    color: #808080;
    font-size: 13px;
    line-height: $s24;
    //padding-bottom: $s16;
  }
  .amount {
    color: $success;
    font-weight: 600;
  }
}
