.BackgroundMotifs {
  .motif1,
  .motif2,
  .motif3 {
    position: absolute;
  }
  .motif1 {
    top: 40%;
    left: 56px;
    transform: translate(0, -50%);
  }
  .motif2 {
    bottom: 15%;
    left: 226px;
    transform: translate(0%, 50%);
  }
  .motif3 {
    top: 45%;
    right: 0;
    transform: translateY(-50%);
  }
}
