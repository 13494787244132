@import "./../../assets/scss/variables";
.alert.IconAlert {
  display: flex;
  svg,
  .Icon {
    width: 24px;
    margin-right: $s24;
    vertical-align: text-bottom;
  }
  span {
    max-width: calc(100% - 48px);
    width: 100%;
  }
  font-size: $s16;
  padding: $s16 $s24;
  &.alert-success {
    border-color: currentColor;
    background: $bg-success;
    color: $success;
    svg {
      margin-top: 2px;
    }
  }
  &.alert-danger {
    border-color: currentColor;
    background: $bg-error;
    color: $error;
  }
  &.alert-light {
    justify-content: center;
    span {
      width: auto;
    }
  }
  &.alert-info{
    color:#fff;
    background:rgba(#1a202c,0.7);
    border:0;
    padding:16px;
  }
}
