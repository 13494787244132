$color-primary: #5dbaf3;
$color-secondary: #1c3849;

$success: #2c7a7b;
$bg-success: #e6fffa;

$error: #c33135;
$bg-error: #fef0f0;

$font-family: "Avenir Next", sans-serif;
$font-family-alt: "Open Sans", sans-serif;

// Spaces

$s4: 4px;
$s8: 8px;
$s16: 16px;
$s24: 24px;
$s32: 32px;
$s40: 40px;
$s48: 48px;
$s64: 64px;
$s72: 72px;
$s80: 80px;
$s128: 128px;
$s200: 200px;
