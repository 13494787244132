.Logo {
  max-width: 100%;
  svg {
    max-width: 100%;
  }
  &.is-white {
    svg * {
      fill: #fff;
    }
  }
}
