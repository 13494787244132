@import "./../../assets/scss/variables";

.PartnerTransaction {
  width: 100%;
  font-family: $font-family-alt;
  .details {
    color: #000000;
    display: flex;
    font-family: $font-family-alt;
    font-style: normal;
    font-weight: normal;
    font-size: $s16;
    justify-content: space-between;
    line-height: $s24;
  }
  p {
    margin-bottom: 0;
  }
  .CardCode {
    font-weight: 600;
  }
  .date {
    border-bottom: 1px solid #ebebeb;
    color: #808080;
    font-size: 13px;
    line-height: $s24;
    padding-bottom: $s16;
  }
  .amount {
    font-weight: 600;
  }
}
