@import "./../../assets/scss/variables";
.PageDebiting {
  width: 100%;
  min-height: 100vh;
  position: relative;
  .innerPageDebiting {
    max-width: 455px;
    padding: $s40 20px;
    width: 100%;
    margin: 0 auto;
    z-index: 10;
    position: relative;
  }
  .CardPreview {
    margin: 52px 0 $s40;
  }
  .IconAlert {
    margin-bottom: $s16;
  }
  .Input {
    margin-bottom: $s16;
  }
  .description{
    background:#fff;
    font-size: 16px;
    padding: 16px 24px;
    margin-bottom: $s16;
    border-radius: 4px;
  }
}
