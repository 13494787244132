@import "./../../assets/scss/variables";
.PageCardForm {
  width: 100%;
  min-height: 100vh;
  background: url(./../../assets/images/bg_form.jpg) center center no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  .form {
    width: 100%;
    max-width: 410px;
    padding: 20px;
    position: relative;
    z-index: 1;
    .Logo {
      max-width: 210px;
      margin-bottom: $s40;
    }
    .Input {
      /*label {
        color: #fff;
      }*/
      input {
        height: $s72;
      }
      margin-bottom: $s16;
    }
    .btn {
      margin-top: $s24;
    }
  }
}
