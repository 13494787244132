@import "./../../assets/scss/variables";
.PeriodInfo {
  background: #fff;
  padding: $s24 $s32;
  border-radius: $s16;
  width: 100%;
  box-sizing: border-box;
  .btn.btn-sm {
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
  }
  .Input select {
    font-size: 16px;
  }
  .row {
    margin-left: -5px;
    margin-right: -5px;
    .col-8,
    .col-4 {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .selection {
    margin-bottom: $s24;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .information {
    margin-top: $s8;
    font-family: "Inter", sans-serif;
  }
}
