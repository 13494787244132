@import "./../../assets/scss/variables";
.PagePartenaire {
  padding-top: 20px;
  .container {
    max-width: 440px;
    position: relative;
    z-index: 1;
  }
  h2 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    font-family: $font-family-alt;
    margin: $s32 0 $s24;
  }
  .PeriodInfo {
    margin-bottom: $s16;
  }
  .ListTransactions {
    padding-bottom: $s32;
  }
  .btn.btn-sm {
    padding-top: 11px;
    padding-bottom: 11px;
  }
}
