@import "./../../assets/scss/variables";
.CardCode {
  > div {
    display: inline-block;
    margin-right: $s8;
    &:last-child {
      margin-right: 0;
    }
  }
}
