.Icon {
  &.is-loading {
    &:after {
      display: block;
      content: " ";
      width: 20px;
      height: 20px;
      border-radius: 999vw;
      border: 2px solid currentColor;
      border-color: currentColor currentColor transparent transparent;
      animation-name: iconloading;
      animation-duration: 0.5s;
      //animation-timing-function: ease-in-out;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes iconloading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
