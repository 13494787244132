@import "./../../assets/scss/variables";
.ListTransactions {
  background: #fff;
  padding: $s24 36px;
  border-radius: $s16;
  > .PartnerTransaction {
    margin-bottom: $s24;
    &:last-child {
      border-bottom: 0;
      margin-bottom: 0;
      padding-bottom: 0;
      .date {
        padding-bottom: 0;
        border-bottom: 0;
      }
    }
  }
}
