@import "./../../assets/scss/variables";

body .btn {
  //height:62px;
  //width:100%;
  font-weight: 600;
  font-size: 18px;
  line-height: $s24;
  border-radius: $s8;
  transition: 0.3s all ease-out;
  color: #fff;
  padding: $s16;
  font-family: $font-family;
  &.btn-light {
    background: #fff;
    color: #000;
  }
  &.btn-sm {
    padding: 12px $s24;
  }
  &.btn-lg {
    padding: $s24 $s16;
  }
  svg {
    margin-left: $s8;
  }
  &.is-active,
  &.active {
    position: relative;
    color: rgba(#fff, 0.2);
    &.btn-light {
      color: rgba(#000, 0.2);
      &:after {
        border-color: #000 #000 transparent transparent;
      }
    }
    &:after {
      position: absolute;
      display: block;
      content: " ";
      border-radius: 999vw;
      width: 20px;
      height: 20px;
      border: 2px solid #fff;
      border-color: #fff #fff transparent transparent;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation-name: loading;
      animation-duration: 0.5s;
      //animation-timing-function: ease-in-out;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
  }
}

@keyframes loading {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
