@import "./../../assets/scss/variables";
.Flex {
  display: flex;
  flex-wrap: wrap;
  > div {
    max-width: 410px;
    width: 100%;
    padding-left: $s8;
    padding-bottom: $s32;
  }
}
